<template>
  <div class="myAssetsBox">
    <NavBar title="我的积分"></NavBar>
    <div class="bannerBox">
      <div class="titleBox">总积分</div>
      <div class="numberBox">
        <!-- {{ $toFixed(balanceUSDT, 4, true) }} <i>≈ {{ $toFixed(balanceCNY, 2, true) }} CNY</i> -->
        {{ $toFixed(balance, 4, true) }}
      </div>
    </div>
    <div class="contentBox">
      <div class="selectBox">
        <!-- <div @click="() => tabActive !== 1 && goRouter('addCoin')">
          <img :src="require(`@/assets/images/personal/myAssets/a1${tabActive === 1 ? '-gray' : ''}.png`)" alt="" />
          <div :class="{ gray: tabActive === 1 }">充值</div>
        </div> -->
        <div @click="() => tabActive !== 1 && goRouter('getCoin')">
          <img :src="require(`@/assets/images/personal/myAssets/a3${tabActive === 1 ? '-gray' : ''}.png`)" alt="" />
          <div :class="{ gray: tabActive === 1 }">提取</div>
        </div>
        <div @click="goRouter('translateCoin')">
          <img src="@/assets/images/personal/myAssets/a2.png" alt="" />
          <div>划转</div>
        </div>
      </div>
      <Space :height="33"></Space>
      <van-tabs type="card" class="noMargin" v-model="tabActive">
        <Space :height="33"></Space>
        <van-tab :title="item.name" v-for="(item, index) of list" :key="index">
          <van-cell
            v-show="item2.name.toLowerCase() !== 'usdt'"
            :title="item2.name"
            :label="item2.alias"
            :is-link="true"
            :center="true"
            @click="goAssetsList(item2)"
            v-for="(item2, index2) of item.userAssetsCoins"
            :key="index2"
          >
            <template #icon>
              <van-image
                :src="item2.iconUrl || require('@/assets/images/personal/myAssets/a4.png')"
                fit="cover"
                :width="parseInt($pxToPxRatio(70), 10)"
                :height="parseInt($pxToPxRatio(70), 10)"
                class="mr20"
              />
            </template>
            <template #default>
              <div class="number">{{ $toFixed(item2.balance, item2.xiaoShuDian, true) }}</div>
              <!-- <div class="numberToPrice">≈ {{ $toFixed(item2.balanceUsdt, 2, true) }}</div> -->
            </template>
          </van-cell>
        </van-tab>
        <!-- <van-tab title="锁仓账户"></van-tab> -->
      </van-tabs>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: {},
  data() {
    return {
      balanceUSDT: 0,
      balanceCNY: 0,
      balance: 0,
      list: [],
      tabActive: 0,
    };
  },
  created() {
    this.getDetail();
  },
  // mounted () {},
  methods: {
    getDetail() {
      this.$http('post', '/v1/user/userAssets', {
        goodsId: this.$store.state.personal.userActive ? 3 : 1,
      }).then(res => {
        this.balanceUSDT = res.data.balanceUSDT;
        this.balanceCNY = res.data.balanceCNY;
        this.balance =
          Number(res.data.userAssetsWalletVos[0].userAssetsCoins[0].balance) +
          Number(res.data.userAssetsWalletVos[1].userAssetsCoins[0].balance);
        this.list = res.data.userAssetsWalletVos;
      });
    },
    goAssetsList(res) {
      this.$router.push(`/personal/myAssets/assetsList?classId=${res.walletAccountId}&coinId=${res.coinId}`);
    },
    goRouter(res) {
      this.$router.push(`/personal/myAssets/${res}`);
    },
  },
  // watch: {},
  // computed: {},
  // filters: {},
  // before-destroy () {},
};
</script>
<style scoped lang="less">
.myAssetsBox {
  .bannerBox {
    height: 272px;
    background: #525298;
    padding: 41px 30px;
    color: @white;
    .titleBox {
      font-size: 24px;
      font-weight: 400;
      line-height: 33px;
      margin-bottom: 22px;
    }
    .numberBox {
      font-size: 48px;
      font-weight: 500;
      line-height: 46px;
      i {
        font-size: 26px;
        display: inline-block;
      }
    }
  }
  .contentBox {
    margin-top: -97px;
  }
  .selectBox {
    width: 690px;
    height: 138px;
    background: #ffffff;
    box-shadow: 0px 2px 12px 2px rgba(212, 212, 239, 0.39);
    border-radius: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 26px;
    font-weight: 400;
    color: @title-color;
    line-height: 37px;
    div {
      text-align: center;
      flex: 1;
      img {
        width: 36px;
        height: 31px;
        margin-bottom: 8px;
      }
    }
  }
  .van-cell {
    padding-left: 0;
    padding-right: 0;
    line-height: 28px;
    &:active {
      background: transparent;
    }
    &:after {
      left: 0 !important;
      right: 0 !important;
    }
    .van-cell__title {
      color: @title-color;
    }
    .van-cell__label {
      color: @intro-color;
      line-height: 24px;
    }
    .van-cell__right-icon {
      color: #cacaca;
    }
  }
  .number {
    color: @title-color;
    margin-bottom: 5px;
  }
  .numberToPrice {
    font-size: 24px;
  }
}
.gray {
  color: @intro-color1;
}
</style>
